/**
 * Komponente ´Form tools´ > ´Clearable fields´
 */

import {
	execute,
	getUid,
	noop
} from '../../../utils/index';

import EventHandler from '../../../dom/event-handler';
import Manipulator  from '../../../dom/manipulator';

import BaseClass from '../../../utils/base-class';

/**
 * @type {string}
 */
const NAME = 'clearable-field';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	// allowedTypes  : ['date', 'datetime-local', 'email', 'file', 'month', 'number', 'password', 'search', 'tel', 'text', 'url', 'week'],
	allowedTypes  : ['email', 'file', 'password', 'search', 'tel', 'text', 'url'],
	classContainer: 'form-control-clearable',
	triggerIcon   : '<i aria-hidden="true" class="fa-solid fa-times"></i>',
	triggerLabel  : 'Clear form field',
	onInit        : noop, // (input) => { console.log('onInit', input); },
	onClear       : noop // (input) => { console.log('onClear', input); }
};

/**
 *  Class
 */
class ClearableField extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = ClearableField.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this.elementType = this._element.getAttribute('type').toLowerCase();

		if (!this._config.allowedTypes.includes(this.elementType)) {
			return;
		}

		this._setup();
	}

	/**
	 * API-methode ´clear()´.
	 */
	clear() {
		this._clear(false);
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_setup() {
		// const elementType = this._element.getAttribute('type').toLowerCase();

		// Aktuelle Element-ID bestimmen.
		const id = this._element.getAttribute('id') || getUid(`${this.constructor.NAME}`);

		// ... Element-ID aktualisieren.
		this._element.setAttribute('id', id);

		// Wrapper-Container integrieren.
		this.wrapper = Manipulator.elementBefore(`<div class="${this._config.classContainer}"/>`, this._element);

		// ... Element in Wrapper-Container verschieben.
		Manipulator.elementAppend(this._element, this.wrapper);

		// Trigger-Button integrieren
		this.trigger = Manipulator.elementAppend(`<button aria-controls="${id}" aria-hidden="true" aria-label="${this._config.triggerLabel}" class="icon-button" hidden tabindex="0" type="button">
			<span class="icon-button__icon">${this._config.triggerIcon}</span>
		</button>`, this.wrapper);

		//
		// Events
		//

		EventHandler.on(this._element,'input', this._handleInput.bind(this));
		EventHandler.on(this._element,'change propertychange clearable.change', this._handleInput.bind(this));
		EventHandler.on(this._element,'propertychange clearable.change', this._handleInput.bind(this));

		EventHandler.on(this.trigger,`click${this.constructor.EVENT_KEY}`, event => {
			event.preventDefault();

			this._clear();
		});

		EventHandler.trigger(this._element, 'propertychange');

		execute(
			this._config.onInit,
			this._element,
		);
	}

	/**
	 * Eingabefeld leeren.
	 *
	 * @param {Boolean} [setFocus=true]
	 * @private
	 */
	_clear(setFocus = true) {

		switch (this.elementType) {
			case 'date':
			case 'datetime-local':
			case 'number':
			case 'month':
			case 'week': {
				const min = this._element.getAttribute('min');

				this._element.value = (min === '') ? '' : min;
				break;
			}

			default: {
				this._element.value = '';
				break;
			}
		}

		// Trigger ausblenden
		this.trigger.hidden = true;

		Manipulator.setAria(this.trigger, 'hidden', true);

		// Focus setzen?
		if (setFocus) {
			this._element.focus();
		}

		// Call events
		execute(
			this._config.onClear,
			this._element,
		);
	}

	/**
	 *
	 * @param {object} event
	 * @private
	 */
	_handleInput(event) {
		const flag = (event.target.value !== '');

		// Sichtbarkeit des Triggers
		this.trigger.hidden = !flag;
		Manipulator.setAria(this.trigger, 'hidden', !flag);
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default ClearableField;

// ------
// Privat
// ------

let featureDetection;

const gatherFeatureData = () => {
	return {
		cssVar: (() => {
			const el = document.createElement("style");

			el.innerHTML = "root: { --tmp-var: bold; }";

			document.head.append(el);

			const flag = !!(window.CSS && window.CSS.supports && window.CSS.supports("font-weight", "var(--tmp-var)"));

			el.remove();

			return flag;
		})(),

		gestures: (() => {
			return 'ongesturestart' in window;
		})(),

		intersectionObserver: (() => {
			return 'IntersectionObserver' in window;
		})(),

		lazyLoading: (() => {
			return 'loading' in HTMLImageElement.prototype;
		})(),

		mutationObserver: (() => {
			return 'MutationObserver' in window;
		})(),

		onOrientationChange : (() => {
			return 'onorientationchange' in window;
		})(),

		pointerEvents: (() => {
			return !!(
				window.PointerEvent &&
				'maxTouchPoints' in window.navigator &&
				window.navigator.maxTouchPoints >= 0
			);
		})(),

		touch: (() => {
			return !!(
				'ontouchstart' in window ||
				(
					window.DocumentTouch &&
					document instanceof window.DocumentTouch
				)
			);
		})(),

		domScopeQuery: (() => {
			const element = document.createElement('div');

			try {
				element.querySelectorAll(':scope *');
			} catch {
				// No support

				return false;
			}

			return true;
		})()
	};
};

// ------
// Public
// ------

const getFeature = () => {
	if (!featureDetection) {
		featureDetection = gatherFeatureData();
	}

	return featureDetection;
};

// Export
export {getFeature};

/**
 * Komponente ´Reveal password´
 */

import {
	getUid,
	extend
} from '../../utils/index';
import {
	isElement,
	isString
} from '../../utils/is';

import SelectorEngine from '../../dom/selector-engine';
import EventHandler   from '../../dom/event-handler';
import Data           from '../../dom/data';
import Manipulator    from '../../dom/manipulator';

// -------
// Private
// -------

const NAME      = 'reveal-password';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const SELECTOR_COMPONENT = `input[type="password"][data-${NAME}]`;

const DEFAULTS = {
	triggerText : '<i aria-hidden="true" class="far"></i>',
	triggerTitle: 'Show/Hide password',
	infoHidden  : 'Password hidden',
	infoVisible : 'Password visible',
};

// ------
// Public
// ------

/**
 * @param {HTMLElement} element
 * @param {Object} o
 */
const revealPassword = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	const uid     = element.getAttribute('id') || getUid('rvpa');
	const wrapper = Manipulator.elementBefore('<div class="reveal-password"/>', element);

	element.setAttribute('id', uid);

	Manipulator.setAria(element,'describedby', `${uid}-info`);
	Manipulator.elementAppend(element, wrapper);

	// Trigger & Info generieren und integrieren.
	const trigger = Manipulator.elementAppend(`<button aria-pressed="false" class="icon-button" role="switch" title="${o.triggerTitle}" type="button"><span class="icon-button__icon">${o.triggerText}</span></button>`, wrapper);
	const info    = Manipulator.elementAppend(`<span class="sr-only" id="${uid}-info">${o.infoHidden}</span>`, wrapper);

	// Event(s) anbinden.
	EventHandler.on(trigger, `click${EVENT_KEY}`, (event) => {
		const status = Manipulator.getAria(trigger, 'pressed');

		event.preventDefault();
		event.stopPropagation();

		// Trigger-Aria-Label aktualisieren.
		Manipulator.setAria(trigger, 'pressed', !status);

		// Elementtyp anpassen.
		element.type = (status) ? 'password' : 'text';

		// Infotext anpassen.
		info.textContent = (status) ? o.infoHidden : o.infoVisible;
	});

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return wrapper;
};

/**
 * Alle vorhandenen ´Reveal password´ initialisieren.
 *
 * @param {null|HTMLElement} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group;

	if (isElement(m)) {
		group = revealPassword(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : SELECTOR_COMPONENT,
			_o.container || document.documentElement
		);

		group = [];

		for (const element of collection) {
			group.push(revealPassword(element, _o));
		}
	}

	return group;
};

// Export
export default {
	init          : init,
	revealPassword: revealPassword
};

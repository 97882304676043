/**
 * Initial
 */

import {focusVisible}  from '../../shared/utils/focus-visible';
import {scrollPageTop} from '../../shared/utils/scroll';
import {loadScript}    from '../../shared/utils/load-script';
import lazyLoad        from '../../shared/utils/lazy-load';

import SelectorEngine from '../../shared/dom/selector-engine';
import Manipulator    from '../../shared/dom/manipulator';
import EventHandler   from '../../shared/dom/event-handler';

/**
 * Accessibility (`:focus` erst bei Tastaturnavigation anzeigen.)
 */
const focusableElements = () => {
	const collection = SelectorEngine.find('input, textarea, select, label, a, button');

	for (const element of collection) {
		focusVisible.observe(element);
	}
};

/**
 * Globaler ´Scroll top´-Button
 * Meistens positioniert am Ende des sichtbaren Viewport.
 */
const scrollTopTriggers = () => {
	// Normaler "ScrollTopTrigger" im Dokument (Inhaltsbereich).
	scrollPageTop();

	//
	// Globaler "ScrollTopTrigger" (meistens am Ende des sichtbaren Viewport).
	// Automatisches Einblenden, nachdem soweit gescrollt wurde wie der Browser
	// aktuell hoch ist.
	//

	const element = SelectorEngine.findOne('[data-scroll-page-top="global"]');

	if (element) {
		window.IfabScrollTopGlobal = element;

		const scrollFunc = () => {
			if (window.scrollY > window.innerHeight) {
				Manipulator.addClass(element, '-is-shown');
			} else {
				Manipulator.removeClass(element, '-is-shown');
			}
		};

		window.addEventListener('scroll', scrollFunc);
	}
};

// -------
// Public
// -------

const initial = () => {
	// Lazyload von Bildern initialisieren.
	lazyLoad(function() {
		const lazyElements = SelectorEngine.find('.lazyload[data-src]');

		if(lazyElements) {
			window.lazySizesConfig          = window.lazySizesConfig || {};
			window.lazySizesConfig.loadMode = 1;

			loadScript(`${window.PageConf.path.dist}shared/vendors/lazysizes/lazysizes.min.js`, {
				async: true,
				id   : 'lazySizes'
			});
		}
	});

	// Accessibility (Focus).
	focusableElements();

	// ´Scroll page top´-Trigger.
	scrollTopTriggers();

	// ´History back´ - Buttons
	EventHandler.on(document.body, 'click', '[data-history-back]', (event) => {
		event.preventDefault();
		event.stopPropagation();

		history.back();
	});
};

// Export
export default initial;

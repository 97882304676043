/**
 * Components
 */

import GMap from '../../shared/components/gmap/gmap';
import Navigation from './custom/navigation/navigation';

// ------
// Public
// ------

const components = () => {
	// const gMapAllowed = (typeof window.ifabCookieConsent !== 'undefined') ? window.ifabCookieConsent.getConsent('googlemaps') : true;
	//
	// if (gMapAllowed && typeof window.pageInstance.settings.maps.google.id) {
	GMap.init(null, {
		apiKey  : window.pageInstance.settings.maps.google.id,
		settings: {
			// Siehe google-maps.js -> DEFAULTS
			map: {
				zoom: 14
			}
		}
	});
	// }

	Navigation.init();
};

// Export
export default components;

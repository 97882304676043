/**
 * Footer
 */

import {observeInView} from '../../../shared/utils/observer';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';

// -------
// Privat
// -------

let footerContainer = null;

// -------
// Public
// -------

const footer = () => {
	footerContainer = footerContainer || SelectorEngine.findOne('#page-footer');

	if (footerContainer)  {
		//
		// Überwachung wann der Footer im ´Viewport´ erscheint.
		//

		// Observer initialisieren.
		observeInView(footerContainer, {
			onEnterViewport: () => {
				Manipulator.addClass(document.documentElement, 'page-footer-in-view');
			},
			onLeaveViewport: () => {
				Manipulator.removeClass(document.documentElement, 'page-footer-in-view');
			},
			observer       : {
				threshold: 0
			}
		});
	}

	return footerContainer;
};

// Export
export default footer;

/**
 * Vendors ´Cookie consent > Tools > Matomo´
 */

import {noop}       from '../../../../shared/utils/index';
import {loadScript} from '../../../../shared/utils/load-script';

import BaseClass from '../../../../shared/utils/base-class';

/**
 * @type {string}
 */
const NAME = 'matomo';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	siteId       : 0,
	trackerDomain: '',
	trackerFile  : 'matomo.php',
	trackerFileJs: 'matomo.js',
	onDisable    : noop,
	onEnable     : noop,

	// Argumente `_paq.push` (verwendet bei Initialisierung)
	setCookieDomain         : window.location.hostname,
	setSecureCookie         : true,
	setVisitorCookieTimeout : 2880, // 2 Tage
	setReferralCookieTimeout: 2800, // 2 Tage
	setSessionCookieTimeout : 0, // Session only
	trackPageView           : true,
	enableLinkTracking      : true,

	// Argumente `_paq.push` (verwendet bei Deaktivierung)
	whenDisable: {
		disableCookies    : true,
		deleteCookies     : true,
		forgetConsentGiven: true,
		optUserOut        : true
	},

	// Argumente `_paq.push` (verwendet bei Aktivierung)
	whenEnable : {
		enableHeartBeatTimer : 30
	}
};

/**
 *  Class
 */
class Matomo extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		super(element, config);

		this.isReady = false;

		if (this._config.siteId && this._config.trackerDomain) {
			this.isReady = true;

			window._paq = window._paq || [];

			if (this._config.trackerDomain.slice(-1) !== '/') {
				this._config.trackerDomain = `${this._config.trackerDomain}/`;
			}

			window._paq.push(
				['setCookieDomain', this._config.setCookieDomain],
				['setSecureCookie', this._config.setSecureCookie],
				['setVisitorCookieTimeout', this._config.setVisitorCookieTimeout],
				['setReferralCookieTimeout', this._config.setReferralCookieTimeout],
				['setSessionCookieTimeout', this._config.setSessionCookieTimeout]
			);

			if (this._config.trackPageView) {
				window._paq.push(["trackPageView"]);
			}

			if (this._config.enableLinkTracking) {
				window._paq.push(["enableLinkTracking"]);
			}
		}
	}

	/**
	 * Matomo aktivieren
	 */
	enable() {
		if (this.isReady) {
			window._paq.push(
				['setTrackerUrl', `${this._config.trackerDomain}${this._config.trackerFile}`],
				['setSiteId', this._config.siteId],
				['enableHeartBeatTimer', this._config.whenEnable.enableHeartBeatTimer],
				['rememberConsentGiven']
			);

			loadScript(`${this._config.trackerDomain}${this._config.trackerFileJs}`, {
				async: true,
				defer: true,
				id   : 'matomo'
			});

			this._config.onEnable();
		}
	}

	/**
	 * Matomo deaktivieren
	 */
	disable () {
		if (this.isReady) {
			for (const [key, value] of Object.entries(this._config.whenDisable)) {
				if (value) {
					window._paq.push([key]);
				}
			}

			this._config.onDisable();
		}
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default Matomo;

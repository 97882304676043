/**
 * Header
 */

import {
	debounce,
	setRootVar
} from '../../../shared/utils/index';
import {observeInView} from '../../../shared/utils/observer';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';

// -------
// Privat
// -------

let headerContainer = null;

/**
 * Aktuelle Höhe des Seitenkopfes, zwecks ´scroll top etc.´ per Rootvariable
 * bekanntgegeben.
 */
const updateGlobalScrollTopOffset = () => {
	setRootVar('global-scroll-top-offset', `${headerContainer.offsetHeight}px`);
};

/**
 * Erkenne wann der Seitenkopf "real" sticky wird.
 * Dazu wird die Hilfe eines ´Sentinel-Elementes´ benötigt. Dieses Element wird
 * überwacht (Viewport verlassen) und entspr. Events werden ausgeführt (Switch
 * von CSS-Klassen bspw.).
 */
const observeStickyHeader = () => {
	// Sentinel generieren.
	const sentinel       = Manipulator.elementBefore('<div class="page-header-sentinel"/>', headerContainer);
	const sentinelTarget = document.documentElement;

	// Observer initialisieren.
	observeInView(sentinel, {
		onEnterViewport: () => {
			Manipulator.removeClass(sentinelTarget, 'page-header-is-sticky');

			updateGlobalScrollTopOffset();
		},
		onLeaveViewport: () => {
			Manipulator.addClass(sentinelTarget, 'page-header-is-sticky');

			updateGlobalScrollTopOffset();
		},
		observer       : {
			threshold: 1
		}
	});
};

// -------
// Public
// -------

const header = () => {
	headerContainer = headerContainer || SelectorEngine.findOne('#page-header');

	if (headerContainer) {
		const compStyle = window.getComputedStyle(headerContainer);

		if (compStyle.getPropertyValue('position') === 'sticky') {
			// Wurde der Seitenkopf als sticky per CSS deklariert, dann muss
			// eine entsprechende Überwachung des "realen" sticky-Status
			// angebunden werden.
			observeStickyHeader();
		}

		updateGlobalScrollTopOffset();

		window.addEventListener('resize', debounce(() => {
			updateGlobalScrollTopOffset();
		}, 200));
	}

	return(headerContainer);
};

// Export
export default header;

// -------
// Private
// -------

const getConstructor = (v) => (v !== null && v !== undefined ? v.constructor : null);

// ------
// Public
// ------

/**
 * Prüfe auf ´array´.
 *
 * @param {*} t
 * @returns {boolean}
 */
const isArray = (t) => getConstructor(t) === Array;

/**
 * Ist Objekt ein Element (Nativ || jQuery).
 *
 * @param {Object} obj
 * @returns {boolean}
 */
const isElement = obj => {
	if (!obj || typeof obj !== 'object') {
		return false;
	}

	if (obj.jquery !== undefined) {
		obj = obj[0];
	}

	return obj.nodeType !== undefined;
};

/**
 * Ist Element ´disabled´.
 *
 * @param {HTMLElement, Element} element
 * @returns {boolean}
 */
const isDisabled = element => {
	if (!element || element.nodeType !== Node.ELEMENT_NODE) {
		return true;
	}

	if (element.classList.contains('_disabled')) {
		return true;
	}

	if (element.disabled !== undefined) {
		return element.disabled;
	}

	return element.hasAttribute('disabled') && element.getAttribute('disabled') !== 'false';
};

/**
 * Prüfe auf ´node´.
 *
 * @param {*} t
 * @returns {boolean}
 */
const isNode = (t) => {
	if (
		typeof window !== 'undefined' &&
		window.HTMLElement !== undefined
	) {
		return t instanceof HTMLElement;
	}

	return t && (t.nodeType === 1 || t.nodeType === 11);
};

/**
 * Prüfe auf ´number´.
 *
 * @param {*} t
 * @returns {boolean}
 */
const isNumber = (t) => getConstructor(t) === Number && !isNaN(t);

/**
 * Prüfe auf ´object´.
 *
 * @param {*} t
 * @returns {boolean}
 */
const isObject = (t) => getConstructor(t) === Object;

/**
 * 	Dokumentausrichtung ´Right to Left´.
 *
 * @returns {boolean}
 */
const isRtl = () => document.documentElement.dir === 'rtl';

/**
 * Prüfe auf ´string´.
 *
 * @param {*} t
 * @returns {boolean}
 */
const isString = (t) => getConstructor(t) === String;

/**
 * Ist Element sichtbar.
 *
 * @param {HTMLElement} element
 * @returns {boolean}
 */
const isVisible = element => {
	if (!isElement(element) || element.getClientRects().length === 0) {
		return false;
	}

	return getComputedStyle(element).getPropertyValue('visibility') === 'visible';
};

// Export
export {
	isArray,
	isElement,
	isDisabled,
	isNode,
	isNumber,
	isObject,
	isRtl,
	isString,
	isVisible,
};

/**
 * Komponente ´Form tools´ > ´Native form validation´
 */

import {
	execute,
	noop
} from '../../../utils/index';

import SelectorEngine from '../../../dom/selector-engine';
import EventHandler   from '../../../dom/event-handler';
import Manipulator    from '../../../dom/manipulator';

import BaseClass from '../../../utils/base-class';

/**
 * @type {string}
 */
const NAME = 'nfv';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	scrollToInvalidField: true,
	onInit              : noop, // (event) => { console.log('onInit', event); },
	onInvalid           : noop, // (event) => { console.log('onInvalid', event); },
	onValid             : noop //(event) => { console.log('onValid', event); }
};

/**
 *  Class
 */
class NativeFormValidation extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = NativeFormValidation.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this._setup();
	}

	/**
	 * API-methode ´getForm()´.
	 */
	getForm() {
		return this._element;
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_setup() {
		EventHandler.on(this._element, `submit${this.constructor.EVENT_KEY}`, event => {
			if (this._element.checkValidity() === false) {
				const form             = this._element;
				const invalidFieldsets = SelectorEngine.find('fieldset:invalid', this._element);
				const invalidFields    = SelectorEngine.find(':invalid:not(fieldset)', this._element);

				event.preventDefault();
				event.stopPropagation();

				if (invalidFields.length > 0) {
					if (this._config.scrollToInvalidField) {
						invalidFields[0].scrollIntoView({
							left    : 0,
							block   : 'start',
							behavior: 'smooth'
						});
					}

					const eventInvalid = EventHandler.trigger(this._element, `onInvalid${this.constructor.EVENT_KEY}`, {
						form,
						invalidFieldsets,
						invalidFields
					});

					execute(
						this._config.onInvalid.bind({
							invalidFieldsets,
							invalidFields
						}),
						eventInvalid
					);
				} else {
					const eventValid = EventHandler.trigger(this._element, `onValid${this.constructor.EVENT_KEY}`, {
						form
					});

					execute(
						this._config.onValid.bind({
							invalidFieldsets,
							invalidFields
						}),
						eventValid
					);
				}
			}

			Manipulator.addClass(this._element, 'was-validated');
		});

		const eventInit = EventHandler.trigger(this._element, `onInit${this.constructor.EVENT_KEY}`);

		execute(
			this._config.onInit,
			eventInit
		);
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default NativeFormValidation;

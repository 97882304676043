/**
 * Komponents
 */

import Button         from './button/button';
import Details        from './details/details';
import TabGroup       from './tab-group/tab-group';
import Slider         from './slider/slider';
import Form           from './form/form';
import Jumplist       from './jumplist/jumplist';
import List           from './list/list';
import MediaGallery   from './media-gallery/media-gallery';
import Player         from './player/player';
import ProgressBar    from './progress-bar/progress-bar';
import ProgressRing   from './progress-ring/progress-ring';
import RevealPassword from './reveal-password/reveal-password';
import ScrollProgress from './scroll-progress/scroll-progress';
import Tag            from './tag/tag';

const index = () => {
	Button.init();
	Details.init();
	TabGroup.init();
	Slider.init();
	Form.init();
	Jumplist.init();
	List.init();
	MediaGallery.init();
	Player.init();
	ProgressBar.init();
	ProgressRing.init();
	RevealPassword.init();
	ScrollProgress.init();
	Tag.init();
};

export default index;

/**
 * Search
 */

import {
	getElementFromSelector,
	executeAfterTransition,
	triggerReflow
} from '../../../shared/utils/index';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';
import Data           from '../../../shared/dom/data';
import EventHandler   from '../../../shared/dom/event-handler';

// -------
// Privat
// -------

let searchBarTriggers  = null;
let searchBarContainer = null;

const searchTriggerStatus = (flag) => {
	for (const element of searchBarTriggers) {
		if (flag) {
			Manipulator.addClass(element, '-active');
		} else {
			Manipulator.removeClass(element, '-active');
		}
	}
};

const searchBarOpen = () => {
	// Hidden-Status entfernen.
	searchBarContainer.hidden = false;
	triggerReflow(searchBarContainer);
	Manipulator.setAria(searchBarContainer, 'hidden', false);

	// CSS-Klassen setzen
	Manipulator.addClass(document.body, '-page-search-open');
	Manipulator.addClass(searchBarContainer, '-open');

	// Suchbarstatus ´on´.
	Data.set(searchBarContainer, 'ifab.searchActive', true);

	// Event ´keyup´ (ESC) schließt Suchbar.
	EventHandler.one(document.body, 'keyup.ifab.pageSearchEsc', (ev) => {
		var k = ev.key || ev.keyCode;

		if (k === 'Escape' || k === 'esc' || k === 27) {
			EventHandler.trigger(searchBarContainer, 'hide.ifab.pageSearch');
		}
	});

	// Event ´click´ (Document) schließt Suchbar (outside click).
	EventHandler.on(document.body, 'click.ifab.pageSearchOutside', () => {
		EventHandler.trigger(searchBarContainer, 'hide.ifab.pageSearch');
	});

	// Trigger aktualisieren
	searchTriggerStatus(true);
};

const searchBarClose = () => {
	// Trigger aktualisieren
	searchTriggerStatus(false);

	// Wenn geschlossen (Transition abwarten).
	executeAfterTransition(() => {
		// Hidden-Status setzen.
		Manipulator.setAria(searchBarContainer, 'hidden', true);
		searchBarContainer.hidden = true;
	}, searchBarContainer);

	Manipulator.removeClass(document.body, '-page-search-open');
	Manipulator.removeClass(searchBarContainer, '-open');

	// Suchbarstatus ´off´.
	Data.remove(searchBarContainer, 'ifab.searchActive');

	// Event ´keyup´ (ESC) entfernen.
	EventHandler.off(document.body, 'keyup.ifab.pageSearchEsc');

	// Event ´click´ (Document) entfernen.
	EventHandler.off(document.body, 'keyup.ifab.pageSearchOutside');
};

// -------
// Public
// -------

const search = () => {
	searchBarTriggers = searchBarTriggers || SelectorEngine.find('[aria-controls="page-search"]');

	if (searchBarTriggers.length) {
		searchBarContainer = searchBarContainer || getElementFromSelector(searchBarTriggers[0]);
	}

	// Keine Trigger und/oder kein Container vorhanden.
	if(!searchBarTriggers || searchBarTriggers.length < 1 || !searchBarContainer) {
		return;
	}

	// Eingabefeld
	const searchField = SelectorEngine.findOne('.search-box__input', searchBarContainer);

	//
	// Events: Suchbar
	//

	// Click-Events auf/innerhalb der Suchbar nicht ´durchreichen´.
	EventHandler.on(searchBarContainer, 'click.ifab.pageSearch', (ev) => {
		ev.stopPropagation();
	});

	// ... öffnen.
	EventHandler.on(searchBarContainer, 'show.ifab.pageSearch', () => {
		searchBarOpen();

		// Suchfeld fokussieren.
		if (searchField) {
			searchField.focus();
		}
	});

	// ... schließen.
	EventHandler.on(searchBarContainer, 'hide.ifab.pageSearch', () => {
		// Suchfeld leeren
		if (searchField) {
			searchField.value = '';
		}

		searchBarClose();
	});

	//
	// Events: Trigger
	//

	for (const trigger of searchBarTriggers) {
		EventHandler.on(trigger, 'click.ifab.pageSearchTrigger', (ev) => {
			const cmd = (Data.get(searchBarContainer, 'ifab.searchActive')) ? 'hide' : 'show';

			ev.preventDefault();
			ev.stopPropagation();

			EventHandler.trigger(searchBarContainer, `${cmd}.ifab.pageSearch`);
		});
	}

	return searchBarContainer;
};

// Export
export default search;

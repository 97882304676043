/**
 * Komponente ´Spinner´
 */

import SelectorEngine from '../../dom/selector-engine';
import Manipulator    from '../../dom/manipulator';

// -------
// Private
// -------

const NAME = 'spinner';
// const DATA_KEY = `ifab.${NAME}`;
// const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

// ------
// Public
// ------

/**
 * Spinner-Element einem Element hinzufügen.
 *
 * @param {HTMLElement|Node} target
 */
const addTo = (target) => {
	if (!SelectorEngine.findOne(`.${NAME}`, target)) {
		const spinner = Manipulator.createElementFrom(`<span aria-busy="true" aria-live="polite" class="${NAME}" role="status"/>`);

		Manipulator.elementAppend(spinner, target);
	}
};

/**
 * Spinner-Element von einem Element entfernen.
 *
 * @param {HTMLElement|Node} target
 */
const removeFrom = (target) => {
	const spinner = SelectorEngine.find(`.${NAME}`, target);

	for (const element of spinner) {
		element.remove();
	}
};

// Export
export default {
	addTo     : addTo,
	removeFrom: removeFrom
};

/**
 * Navigation
 */

import {needJquery} from '../../../../shared/utils/index';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator    from '../../../../shared/dom/manipulator';
import EventHandler   from '../../../../shared/dom/event-handler';

// -------
// Private
// -------

const $           = needJquery();
const pageHeader   = SelectorEngine.findOne('#page-header');
const pageMainMenu = (pageHeader) ? SelectorEngine.findOne('#main-nav') : null;
const pageSubMenu = (pageHeader) ? SelectorEngine.findOne('#sub-nav') : null;

let subNav = null;

/**
 * SubMenu Header Bottom
 */
const setupSubMenu = () => {
	const collection = SelectorEngine.find('.nav-item', pageMainMenu);

	if(collection.length) {
		for (const trigger of collection) {
			EventHandler.on(trigger, 'click.ifab.pageSubMenu', function (event) {
				event.preventDefault();

				buildSubMenu(trigger);
			});
		}

		if(!pageSubMenu.innerHTML) {
			buildSubMenu(collection[0]);
		}
	}
};

const buildSubMenu = (element) => {
	let navList  = SelectorEngine.parents(element, '.nav-list')[0];
	let siblings = SelectorEngine.children(navList, '.nav-item');

	for (const sibling of siblings) {
		if(sibling.classList.contains('-active')) {
			Manipulator.removeClass(sibling, '-active');
		}
	}

	subNav = element.querySelector('.nav-list');

	Manipulator.addClass(element, '-active');

	pageSubMenu.innerHTML = subNav.outerHTML;
};

// -------
// Public
// -------

const init = () => {
	if (pageMainMenu && pageSubMenu) {
		setupSubMenu();
	}
};

// Export
export default {
	init  : init
};

import l10n from './l10n';

import TranslationManager from '../../shared/service/translation-manager';

// -------
// Private
// -------

const tm = (() => {
	if(!window.ifabTM) {
		window.ifabTM = new TranslationManager();

		window.ifabTM.addLanguages(l10n);
	}

	return window.ifabTM;
})();

// ------
// Public
// ------

// Export
export default {
	icons: {
		close: '<i aria-hidden="true" class="far fa-times"></i>',
		next : '<i aria-hidden="true" class="far fa-chevron-right"></i>',
		prev : '<i aria-hidden="true" class="far fa-chevron-left"></i>'
	},
	l10n: tm
};

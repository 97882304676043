/**
 * Komponente ´Details´
 */

import {
	execute,
	extend,
	getElementFromSelector,
	needJquery,
	noop
} from '../../utils/index';
import {
	isElement,
	isString
} from '../../utils/is';
import {focusVisible} from '../../utils/focus-visible';

import SelectorEngine from '../../dom/selector-engine';
import Manipulator    from '../../dom/manipulator';
import Data           from '../../dom/data';

// -------
// Private
// -------

const NAME       = 'details';
const DATA_KEY   = `ifab.${NAME}`;
// const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = `${DATA_KEY}.data-api`;
// const BASE_CLASS = `${NAME}`;

const DEFAULTS = {
	container: null,
	onHide   : noop,
	onHidden : noop,
	onShow   : noop,
	onShown  : noop
};

const $ = needJquery();

/**
 * ´Details´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	// const toggle = SelectorEngine.findOne(SELECTOR_TOGGLE, element);
	const panel  = SelectorEngine.findOne(`.${NAME}__body`, element);

	// Events mit jQuery erweitern :(.
	// Sonstige Möglichkeiten erfordern komplette separate Initialisierungen.
	$(panel)
		.on('show.bs.collapse', function(event) {
			panel.hidden = false;

			Manipulator.addClass(element, '_active');

			execute(
				o.onShow,
				getElementFromSelector(event.currentTarget),
				panel,
				event
			);
		})
		.on('shown.bs.collapse', function(event) {
			execute(
				o.onShown,
				getElementFromSelector(event.currentTarget),
				panel,
				event
			);
		})
		.on('hide.bs.collapse', function(event) {
			Manipulator.removeClass(element, '_active');

			execute(
				o.onHide,
				getElementFromSelector(event.currentTarget),
				panel,
				event
			);
		})
		.on('hidden.bs.collapse', function(event) {
			panel.hidden = true;

			execute(
				o.onHidden,
				getElementFromSelector(event.currentTarget),
				panel,
				event
			);
		});

	focusVisible.observe(element);

	// Initialisierungsstatus setzen.
	Data.set(element,`${DATA_KEY}.initialized`, true);

	return element;
};

// -------
// Public
// -------

/**
 * ´Details´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group;

	if (isElement(m)) {
		group = render(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `[data-c="${NAME}"]`,
			_o.container || document.documentElement
		);

		group = [];

		for (const element of collection) {
			group.push(render(element, _o));
		}
	}

	return group;
};

// Export
export default {
	init: ($ ? init : noop)
};

/**
 * Main(-JS-File)
 */

import {getRootVar} from '../shared/utils/index';

import componentShared  from '../shared';
import componentsCustom from './components/components';
import initial          from './initial/initial';
import layout           from './layout/layout';
import vendors          from './vendors/vendors';

// --------
// Let's go
// --------

document.addEventListener('DOMContentLoaded', function() {
	window.RootvarPrefix = getRootVar('global-rootvar-prefix');

	componentShared();
	componentsCustom();
	vendors();
	initial();
	layout();
});
